import React, { useState } from "react";
import { Stack, Box, Typography } from "@mui/material";
//import { useTranslation } from "react-i18next";
//import { useTheme } from "@emotion/react";
import { connectWallet } from '../../slices/auth';

import { useDispatch } from 'react-redux'

import styles from './Navigation.module.css';
import Logo from '../../assets/images/logo.svg';


const Navigation = ({ darkMode }) => {
  //const theme = useTheme();
  const dispatch = useDispatch()

  const [isConnect, setIsConnect] = useState(false);

  //const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  //const { t } = useTranslation();

  const handleConnectWallet = async () => {
    if (typeof window.ethereum !== "undefined") {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setIsConnect(true);
      dispatch(connectWallet(accounts[0]));
      
      //let user = getWhitelistedUser(accounts[0]);
      //dispatch(setWhitelistedUser(user));
    }
  };

  const handleDisconnectWallet = () => {
    if (isConnect) {
      window.location.reload();
    }
    setIsConnect(false);

  };
  
  return (
    <Stack
      style={{
        border: '1px solid #FEFEFE',
        position: "relative",
        height: 55,
      }}
      direction='row'
      alignItems='center'
      justifyContent='space-between'
    >
      <Box className={styles.logoWrapper}>
        <img src={Logo} alt='logo' />
      </Box>

      <Box 
        className={styles.buttonWrapper}
        onClick={isConnect ? handleDisconnectWallet : handleConnectWallet}
      >
        <Typography className={styles.buttonCaption}>
          {!isConnect ? "Connect Wallet" : "Disconnect"}
        </Typography>
      </Box>
    </Stack>
  );
};

export default Navigation;
