import React, { useEffect, useState } from "react";
import BigNumber from 'bignumber.js';
import axios from "axios";
import { useDispatch } from 'react-redux'
import { setmintProcess } from '../../slices/auth';

import {
    Button,
    Modal,
    Stack,
    Typography
} from "@mui/material";

import { Box } from "@mui/system";

import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';


import styles from './UserForm.module.css';
import apiClient from "../../service/http-common";
import World from '../../assets/images/world.svg';

const pinataAPIKey = "8a48ddcdd5cf40a8b26e";
const pinataSecretAPIKey = "a60690feb421bcdd93df5f9700ce7096cf502fc6464d10a2f81c3c6f92e3cb8a";

const UserForm = ({
    openModal,
    handleCloseModal,
    user,
    userType,
    isMinted2022,
    isMinted2023,
    isMintedStaff,
    connectedWallet,
    nftContract,
    isChecked2023NFT,
    mintProcess,
}) => {

    const dispatch = useDispatch()

    const [userInfo, setUserInfo] = useState({
        dateOfBirth: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        wallet: "",
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(user !== undefined) {
            setUserInfo({
                dateOfBirth: user.userInfo.dateOfBirth,
                firstName: user.userInfo.firstName,
                lastName: user.userInfo.lastName,
                phoneNumber: user.userInfo.phoneNumber,
                email: user.userInfo.email,
                wallet: user.wallet
            });
        }

    }, [user]);

    const handleChangeForm = (event) => {
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value,
        });
    }

    // const getEthPrice = async () => {
    //     const response = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd`);
    //     return response.data.ethereum.usd;
    // }

    // const getETHAmountfromUSD = (ethPrice, usdAmount) => {
    //     let ethAmount = (usdAmount / ethPrice ).toFixed(7);
    //     return parseFloat(ethAmount);
    // }

    const toWei = (value, decimals = 18) => {
        let convertedBalance = new BigNumber(value *  10 ** decimals).toString();
        return convertedBalance;
    }

    const generateMetadataJSONBodies = async () => {

        let tokenId = await nftContract.methods.getLatestTokenId().call();
        tokenId ++;
        
        let JSONBodies = [];

        // mint 2023 by new user
        if(userType === '1' && !isMinted2023) {
            let body = {
                name: "Hodler Miami FC Member #" + tokenId,
                description: "This is Hodler Miami FC Player.",
                image: "https://gateway.pinata.cloud/ipfs/QmNSByPpD1itnXhpgwWueNeC9aRve3XwQ4FHBTjVKv7m4E?_gl=1*184sik5*_ga*MTg4NTE5Mjc2OC4xNjcyODU2NDg3*_ga_5RMPXG14TE*MTY3NDQ1OTIwMi41LjEuMTY3NDQ1OTIyOS4zMy4wLjA",
                price: 0,
                type: 1,
                attributes: [
                    {
                        "trait_type": "Type", 
                        "value": "Player"
                    },
                    {
                      "trait_type": "Year", 
                      "value": "2023"
                    }
                ]
            }

            JSONBodies.push(body);
            return JSONBodies;
        }

        // mint 2022
        if(userType === '2') {
            let body = {
                name: "Hodler Miami FC Member #" + tokenId,
                description: "This is Hodler Miami FC Player.",
                image: "https://gateway.pinata.cloud/ipfs/QmPYxvt33KaGe2e8v9grS2xAdRBqqsWAiK9CoJsYTnvhpU?_gl=1*o9ukox*_ga*MTg4NTE5Mjc2OC4xNjcyODU2NDg3*_ga_5RMPXG14TE*MTY3NDQ1OTIwMi41LjEuMTY3NDQ1OTIyOS4zMy4wLjA",
                price: 0,
                type: 2,
                attributes: [
                    {
                        "trait_type": "Type", 
                        "value": "Player"
                    },
                    {
                      "trait_type": "Year", 
                      "value": "2022"
                    }
                ]
            }

            JSONBodies.push(body);
            return JSONBodies;
        }

        // mint 2022/2023 by whitelisted user
        if(userType === '3')
        {
            if(!isMinted2022) {
                // 2022 mint
                let body = {
                    name: "Hodler Miami FC Member #" + tokenId,
                    description: "This is Hodler Miami FC Player.",
                    image: "https://gateway.pinata.cloud/ipfs/QmPYxvt33KaGe2e8v9grS2xAdRBqqsWAiK9CoJsYTnvhpU?_gl=1*o9ukox*_ga*MTg4NTE5Mjc2OC4xNjcyODU2NDg3*_ga_5RMPXG14TE*MTY3NDQ1OTIwMi41LjEuMTY3NDQ1OTIyOS4zMy4wLjA",
                    price: 0,
                    type: 2,
                    attributes: [
                        {
                            "trait_type": "Type", 
                            "value": "Player"
                        },
                        {
                            "trait_type": "Year", 
                            "value": "2022"
                        }
                    ]
                }
                JSONBodies.push(body);
                tokenId ++;
            }
    
            // 2023 mint
            if(!isMinted2023 && (isChecked2023NFT || isMinted2022)) {
                let body = {
                    name: "Hodler Miami FC Member #" + tokenId,
                    description: "This is Hodler Miami FC Player.",
                    image: "https://gateway.pinata.cloud/ipfs/QmNSByPpD1itnXhpgwWueNeC9aRve3XwQ4FHBTjVKv7m4E?_gl=1*184sik5*_ga*MTg4NTE5Mjc2OC4xNjcyODU2NDg3*_ga_5RMPXG14TE*MTY3NDQ1OTIwMi41LjEuMTY3NDQ1OTIyOS4zMy4wLjA",
                    price: 0,
                    type: 1,
                    attributes: [
                        {
                            "trait_type": "Type", 
                            "value": "Player"
                        },
                        {
                          "trait_type": "Year", 
                          "value": "2023"
                        }
                    ]
                }

                JSONBodies.push(body);
            }

            return JSONBodies;
        }

        if(userType === '4' && !isMintedStaff) {
            let body = {
                name: "Hodler Miami FC Member #" + tokenId,
                description: "This is Hodler Miami FC Player.",
                image: "https://gateway.pinata.cloud/ipfs/QmbxzHe642aMMJziSrXuLpyWG2iMYLVmDoUq15ARmQzUuX?_gl=1*1meleti*_ga*MTg4NTE5Mjc2OC4xNjcyODU2NDg3*_ga_5RMPXG14TE*MTY3NDQ1OTIwMi41LjEuMTY3NDQ1OTIyOS4zMy4wLjA",
                price: 0,
                type: 3,
                attributes: [
                    {
                      "trait_type": "Type",
                      "value": "Staff"
                    }
                ]
            }
            JSONBodies.push(body);
        }

        return JSONBodies;
    }

    const pinJSONToIPFS = async (JSONBody) => {
        const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
        return axios 
            .post(url, JSONBody, {
                headers: {
                    pinata_api_key: pinataAPIKey,
                    pinata_secret_api_key: pinataSecretAPIKey,
                }
            })
            .then(function (response) {
                return "https://gateway.pinata.cloud/ipfs/" + response.data.IpfsHash;
            })
            .catch(function (error) {
                console.log(error)
                return {
                    success: false,
                    message: error.message,
                }
        });
    }

    const uploadMetadata = async (user) => {
        let metadataUris = [];
        let JSONBodies = await generateMetadataJSONBodies();

        if(JSONBodies.length > 0) {
            for (let i = 0; i < JSONBodies.length; i ++) {
                let uri = await pinJSONToIPFS(JSONBodies[i]);
                let type = JSONBodies[i].type;

                metadataUris.push({uri: uri, price: JSONBodies[i].price, type});
            }    
        }
        // First element is Memberhsip and from the second one, leagues NFT
        return metadataUris;
    }

    const saveUserInfo = async () => {
      
        if(user === undefined) {
            let postData = {
                wallet: connectedWallet,
                name: "Hodler Miami FC Player",
                description: "This is Hodler Miami FC Player", 
                image2023:"https://gateway.pinata.cloud/ipfs/QmduwxhVQCDCfxSRZqwHsRnHZd7Puh3TLHNoTJUj1jYuzP?_gl=1*m2r9c3*_ga*MTg4NTE5Mjc2OC4xNjcyODU2NDg3*_ga_5RMPXG14TE*MTY3NDIyMjQzOC4yLjEuMTY3NDIyMzAwOC41Mi4wLjA",
            
                userInfo : {
                    dateOfBirth : userInfo.dateOfBirth,
                    firstName : userInfo.firstName,
                    lastName : userInfo.lastName,
                    phoneNumber : userInfo.phoneNumber,
                    email: userInfo.email
                },
                isWhitelist : false,
            };
    
            await apiClient.post("users", postData)
            .then(res => {
                console.log("test post", res.data);
            });
        } else {
            let postData;

            if(!user.isStaff) {
                postData = {
                    wallet: connectedWallet,
                    name: "Hodler Miami FC Player",
                    description: "This is Hodler Miami FC Player", 
                    image2022:"https://gateway.pinata.cloud/ipfs/QmT7DQcftoXPMTjVHQxFQcTtGgNwDvgkPSaKm97dcevVeX?_gl=1*1og4yng*_ga*MTg4NTE5Mjc2OC4xNjcyODU2NDg3*_ga_5RMPXG14TE*MTY3NDIyMjQzOC4yLjEuMTY3NDIyMjkzOC4zLjAuMA",
                    image2023:"https://gateway.pinata.cloud/ipfs/QmduwxhVQCDCfxSRZqwHsRnHZd7Puh3TLHNoTJUj1jYuzP?_gl=1*m2r9c3*_ga*MTg4NTE5Mjc2OC4xNjcyODU2NDg3*_ga_5RMPXG14TE*MTY3NDIyMjQzOC4yLjEuMTY3NDIyMzAwOC41Mi4wLjA",
                    
                    userInfo : {
                        dateOfBirth : userInfo.dateOfBirth,
                        firstName : userInfo.firstName,
                        lastName : userInfo.lastName,
                        phoneNumber : userInfo.phoneNumber,
                        email: userInfo.email
                    },
                    isWhitelist: true,
                    isStaff : user.isStaff,
                    mint2022: true,
                    mint2023: isChecked2023NFT
                };
            } else {
                postData = {
                    wallet: connectedWallet,
                    name: "Hodler Miami FC Player",
                    description: "This is Hodler Miami FC Player", 
                    image2022:"https://gateway.pinata.cloud/ipfs/QmT7DQcftoXPMTjVHQxFQcTtGgNwDvgkPSaKm97dcevVeX?_gl=1*1og4yng*_ga*MTg4NTE5Mjc2OC4xNjcyODU2NDg3*_ga_5RMPXG14TE*MTY3NDIyMjQzOC4yLjEuMTY3NDIyMjkzOC4zLjAuMA",
                    image2023:"https://gateway.pinata.cloud/ipfs/QmduwxhVQCDCfxSRZqwHsRnHZd7Puh3TLHNoTJUj1jYuzP?_gl=1*m2r9c3*_ga*MTg4NTE5Mjc2OC4xNjcyODU2NDg3*_ga_5RMPXG14TE*MTY3NDIyMjQzOC4yLjEuMTY3NDIyMzAwOC41Mi4wLjA",
                
                    userInfo : {
                        dateOfBirth : userInfo.dateOfBirth,
                        firstName : userInfo.firstName,
                        lastName : userInfo.lastName,
                        phoneNumber : userInfo.phoneNumber,
                        email: userInfo.email
                    },
                    isWhitelist: true,
                    isStaff : user.isStaff,
                    mintStaff: true
                };
            }

            await apiClient.put("users" + connectedWallet, postData)
            .then(res => {
                console.log("test put", res.data);
            });
        }
    }

    const mint = async () => {

        setLoading(true);
        let metadataUris = await uploadMetadata(user);
        console.log("-------------------", metadataUris);

        for(let i = 0; i < metadataUris.length; i ++) {
            await nftContract
            .methods
            .safeMint(
                metadataUris[i].uri,
                metadataUris[i].type,
                0
            )
            .send({ from: connectedWallet, value: toWei(0) });
        }
        await saveUserInfo();
        setLoading(false);
        dispatch(setmintProcess(!mintProcess));
        handleCloseModal();
    }

    return (
        <Box>
            <Modal
                sx={{ zIndex: 500000 }}
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
            >
                <Box 
                    color={"#ffffff"}
                    className={styles.modalStyle}
                >   
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography id="modal-modal-title" className={styles.itemCaption}>

                            {
                                ((userType === '1' && !isMinted2023) || (userType === '3' && isMinted2022 && !isMinted2023)) 
                                && "Mint 2023"
                            }

                            {
                                (userType === '2' && !isMinted2022) 
                                && "Mint 2022"
                            }

                            {
                                (userType === '3' && !isMinted2022 && !isMinted2023) 
                                && ( isChecked2023NFT ?  "Mint 2022/2023" : "Mint 2022")
                            }
                            {
                                (userType === '4' && !isMintedStaff) && "Mint Staff"
                            }
                        </Typography>
                        <CloseIcon className={styles.closeIcon} onClick={handleCloseModal}/>
                    </Stack>
                    
                    <Box id="modal-modal-description" sx={{ mt: 2, zIndex: 1000 }}>
                        <Typography
                            className={styles.inputLabel}
                        >
                            DATE OF BIRTH
                        </Typography>
                        <input
                            className={styles.inputField}
                            type="date"
                            value={userInfo.dateOfBirth}
                            name="dateOfBirth"
                            onChange={handleChangeForm}
                        />

                        <Typography
                            className={styles.inputLabel}
                        >
                            FIRST NAME
                        </Typography>
                        <input
                            className={styles.inputField}
                            type="text"
                            placeholder="eg. Juan"
                            name="firstName"
                            value={userInfo.firstName}
                            onChange={handleChangeForm}
                        />
                        
                        <Typography
                            className={styles.inputLabel}
                        >
                            LAST NAME
                        </Typography>
                        <input
                            className={styles.inputField}
                            type="text"
                            placeholder="eg. Arango"
                            name="lastName"
                            value={userInfo.lastName}
                            onChange={handleChangeForm}

                        />

                        <Typography
                            className={styles.inputLabel}
                        >
                            PHONE NUMBER
                        </Typography>
                        <input
                            className={styles.inputField}
                            type="text"
                            placeholder="+1 302 616 5555"
                            name="phoneNumber"
                            value={userInfo.phoneNumber}
                            onChange={handleChangeForm}
                        />

                        <Typography
                            className={styles.inputLabel}
                        >
                            Email
                        </Typography>
                        <input
                            className={styles.inputField}
                            type="email"
                            placeholder="arangol18@gmail.com"
                            name="email"
                            value={userInfo.email}
                            onChange={handleChangeForm}
                        />

                        <Button 
                            className={styles.submitButton}
                            onClick={mint}
                            disabled={loading}
                        >
                            <img src={World} alt='world' />
                            <span className={styles.submitButtonCaption}>
                                {loading ? "Pending...  " : "SAVE & Mint"}
                            </span>
                            {
                                loading && <CircularProgress size={25} />
                            } 
                        </Button>
                    </Box>

                </Box>
                
            </Modal>
        </Box>
    )
}

export default UserForm;

