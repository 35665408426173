import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    wallet: "",
    whitelistedUser: null,
    mintProcess: false,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        connectWallet: (state, action) => {
            state.wallet = action.payload;
        },
        setWhitelistedUser: (state, action) => {
            state.whitelistedUser = action.payload;
        },
        setmintProcess: (state, action) => {
            state.mintProcess = action.payload;
        }
    },
    
});

export const { connectWallet, setWhitelistedUser, setmintProcess } = authSlice.actions;
export default authSlice.reducer;