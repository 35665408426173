import React, { useState , useEffect} from "react";
import { 
    Box, 
    Grid, 
    Stack, 
    Typography, 
    useMediaQuery, 
    Alert,
    AlertTitle,
    Checkbox,
    Button,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import clsx from "clsx";
import { useSelector } from 'react-redux';
import axios from "axios";

import UserForm from "../../components/UserForm/UserForm";

import Hodler2022 from '../../assets/images/hodler2022.png';
import Hodler2023 from '../../assets/images/hodler2022.png';

import Web3 from "web3";

import HodlerMiamiFCMember from "../../abis/HodlerMiamiFCMember.json";

import Star from '../../assets/images/star.png';
import Sticker1 from '../../assets/images/sticker1.png';
import Sticker2 from '../../assets/images/sticker2.png';
import World from '../../assets/images/world.svg';
import apiClient from "../../service/http-common";

import styles from './Mint.module.css';

const player2022Img = "https://gateway.pinata.cloud/ipfs/QmT7DQcftoXPMTjVHQxFQcTtGgNwDvgkPSaKm97dcevVeX?_gl=1*1og4yng*_ga*MTg4NTE5Mjc2OC4xNjcyODU2NDg3*_ga_5RMPXG14TE*MTY3NDIyMjQzOC4yLjEuMTY3NDIyMjkzOC4zLjAuMA";
const player2023Img = "https://gateway.pinata.cloud/ipfs/QmduwxhVQCDCfxSRZqwHsRnHZd7Puh3TLHNoTJUj1jYuzP?_gl=1*m2r9c3*_ga*MTg4NTE5Mjc2OC4xNjcyODU2NDg3*_ga_5RMPXG14TE*MTY3NDIyMjQzOC4yLjEuMTY3NDIyMzAwOC41Mi4wLjA";
const staffImg = "https://gateway.pinata.cloud/ipfs/QmNjxabJWyCY2aCS4g9o4P3XMGqpSgzacStyYkmCz3efxM?_gl=1*pc04wc*_ga*MTg4NTE5Mjc2OC4xNjcyODU2NDg3*_ga_5RMPXG14TE*MTY3NDIyMjQzOC4yLjEuMTY3NDIyMzAwOC41Mi4wLjA";

const Mint = ({ darkMode }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const wallet = useSelector((state) => state.auth.wallet);
    const mintProcess = useSelector((state) => state.auth.mintProcess);

    const [ sticker, setSticker ] = useState(Sticker1);
    const [ user, setUser ] = React.useState();
    
    const [ userType, setUserType ] = React.useState('0');
    
    const [ isMinted2022, setIsMinted2022 ] = React.useState(false);
    const [ isMinted2023, setIsMinted2023 ] = React.useState(false);
    const [ isMintedStaff, setIsMintedStaff ] = React.useState(false);

    const [checkboxes, setCheckboxes] = useState({
        year2022: true,
        year2023: false
    });


    const [nftContract, setNFTContract] = useState(0);

    const [totalPrice, setTotalPrice] = useState(0);
    const [totalETHPrice, setTotalETHPrice] = useState(0);

    const [openModal, setOpenModal] = useState(false);


    useEffect(() => {
        const ethEnabled = async () => {
            await fetchDataFromBlockchain();
        };

        if(wallet !== "") {
            ethEnabled();
        }
    }, [wallet, mintProcess]);

    useEffect(() => {
        const getUser  = async () => {
            const response = await apiClient.get("users");
            let whitelist = response.data.users;
            console.log("whitelist ", whitelist);
            let _user = whitelist.filter(user => user.wallet.toLowerCase() === wallet)[0];
            console.log("user ", _user);
            setUser(_user);
        
            let _totalPrice = getTotalPrice(_user);
            if(_totalPrice > 0) {
                let _ethPrice = await getEthPrice();
                let _totalETHPrice = getETHAmountfromUSD(_ethPrice, _totalPrice);
                setTotalETHPrice(_totalETHPrice);
            }
        setTotalPrice(_totalPrice);
      }
      getUser();
    }, [wallet, checkboxes, mintProcess]);

    const fetchDataFromBlockchain = async () => {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
            let web3 = window.web3;

            const _nftContract = new web3.eth.Contract(
                HodlerMiamiFCMember.abi,
                HodlerMiamiFCMember.address
            );
            setNFTContract(_nftContract);

            let _userType = await _nftContract.methods.getUserType(wallet).call();
            setUserType(_userType);

            if(_userType === '1') {
                setIsMinted2023(await _nftContract.methods.isCheckMint2023(wallet).call());
            }
            if(_userType === '2') {
                setIsMinted2022(await _nftContract.methods.isCheckMint2022(wallet).call());
            }

            if(_userType === '3') {
                setIsMinted2022(await _nftContract.methods.isCheckMint2022(wallet).call());
                setIsMinted2023(await _nftContract.methods.isCheckMint2023(wallet).call());
            }

            if(_userType === '4') {
                setIsMintedStaff(await _nftContract.methods.isCheckMintStaff(wallet).call());
            }
        }
    }

    const getEthPrice = async () => {
        const response = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd`);
        return response.data.ethereum.usd;
    }

    const getETHAmountfromUSD = (ethPrice, usdAmount) => {
        let ethAmount = (usdAmount / ethPrice ).toFixed(7);
        return parseFloat(ethAmount);
    }

    const handleChange = (event) => {
        setCheckboxes({
            ...checkboxes,
            [event.target.name]: event.target.checked,
        });
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const getTotalPrice = (_user) => {
        // if(wallet === "") 
        //     return 0;
        // if(_user === undefined)
        //     return 30;
        // if(_user !== undefined && _user.isWhitelist && _user.isStaff && !_user.mintStaff)
        //     return 10;
        // if(_user !== undefined && _user.isWhitelist && !_user.isStaff && !_user.mint2022 && !_user.mint2023) {
        //     if(checkboxes.year2023 === true)
        //         return 20 + 30;
        //     else 
        //         return 20;
        // }
        // if(_user !== undefined && _user.isWhitelist && !_user.isStaff && _user.mint2022 && !_user.mint2023)
        //     return 30;

        return 0;
    }

    return (
        <Box className={styles.container}>
            <Grid
                container
                direction={isMobile ? 'column-reverse' : 'row'}
                className={styles.root}
            >
                <Grid item sm={12} md='auto' className={styles.leftPanel}>
                    <Stack sx={{ width: isMobile ? '100%' : 400 }}>
                        <Box className={styles.orderContainer}>
                            <Typography className={styles.itemCaption}>MINT NFT</Typography>
                            {
                                wallet !== "" ? (
                                    <Box>
                                        {
                                            (userType !== '0' &&  (userType === '2' || userType === '3') && !isMinted2022) && 
                                            <Stack direction='row' justifyContent='space-between' mb={3}>
                                                <Stack direction='row' spacing={2}>
                                                  
                                                    {
                                                        userType === '3' && 
                                                        <Checkbox
                                                            disableRipple
                                                            checked={true} 
                                                            name="year2022"
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    
                                                    <img src={Hodler2022} alt='item' className={styles.itemIcon}/>
                                                    <Box>
                                                        <Typography className={styles.itemLabel}>Mint 2022</Typography>
                                                        <Typography className={styles.itemSubLabel}>PLAYER ANNUAL MEMBERSHIP </Typography>
                                                    </Box>
                                                </Stack>
                                               
                                                <Typography className={styles.itemValue}>$0</Typography>
                                            </Stack>
                                        }

                                        {
                                            (userType !== '0' &&  (userType === '1' || userType === '3') && !isMinted2023) && 
                                            <Stack direction='row' justifyContent='space-between' mb={3}>
                                                <Stack direction='row' spacing={2}>
                                                    {
                                                        (userType === '3' && !isMinted2022) && 
                                                        <Checkbox
                                                            disableRipple
                                                            checked={checkboxes.year2023} 
                                                            name="year2023"
                                                            onChange={handleChange}
                                                        />
                                                    }

                                                    <img src={Hodler2023} alt='item' className={styles.itemIcon}/>
                                                    <Box>
                                                        <Typography className={styles.itemLabel}>Mint 2023</Typography>
                                                        <Typography className={styles.itemSubLabel}>PLAYER ANNUAL MEMBERSHIP</Typography>
                                                    </Box>
                                                </Stack>
                                                <Typography className={styles.itemValue}>$0</Typography>
                                            </Stack>
                                        }

                                        {
                                            (userType !== '0' && userType === '4' && !isMintedStaff) && 
                                            <Stack direction='row' justifyContent='space-between' mb={3}>
                                                <Stack direction='row' spacing={2}>
                                                  
                                                    <img src={Hodler2023} alt='item' className={styles.itemIcon}/>
                                                    <Box>
                                                        <Typography className={styles.itemLabel}>Mint Staff </Typography>
                                                        <Typography className={styles.itemSubLabel}>PLAYER ANNUAL MEMBERSHIP </Typography>
                                                    </Box>
                                                </Stack>
                                                <Typography className={styles.itemValue}>$0</Typography>
                                            </Stack>
                                        }
                                    
                                    </Box>
                                ) : ("")
                            }
                        </Box>

                        <Box className={styles.orderLabel}>
                        <Typography className={styles.label}>
                            Order Details
                        </Typography>
                        </Box>

                        <Box className={styles.orderDetail}>
                        <Stack spacing={2}>
                            <Stack direction='row' justifyContent='space-between' mb={1}>
                                <Typography className={styles.itemLabel}>Total</Typography>
                                <Typography className={styles.itemValue}>${totalPrice}</Typography>
                            </Stack>

                            <Stack direction='row' justifyContent='space-between' mb={4}>
                                <Typography className={styles.itemLabel}>Total ETH</Typography>
                                <Typography className={styles.itemValue}>{totalETHPrice} ETH</Typography>
                            </Stack>

                            <Button 
                                className={styles.submitButton} 
                                onClick={handleOpenModal}
                                disabled={wallet === "" || userType === '0' ?  true : false }
                            >
                                <img src={World} alt='world' />
                                <span className={styles.submitButtonCaption}>
                                    Mint
                                </span>
                            </Button>
                        </Stack>
                        </Box>
                    </Stack>

                    <img src={sticker} className={clsx(styles.sticker, {mobile: isMobile})}
                        onMouseOver={() => setSticker(Sticker2)}
                        onMouseOut={() => setSticker(Sticker1)}
                        alt="sticker"
                    />
                </Grid>
            
                <Grid item sm={12} md
                    className={styles.content} 
                >
                    <Stack direction={isMobile ? 'column' : 'row'} spacing={3} justifyContent='center' alignItems='center' >
                        {
                            wallet === "" ? (
                                <Alert icon={false} severity="info"  sx={{width: '100%'}}>
                                    Connect your wallet to see the NFTs available to mint.
                                </Alert>
                        ) : (
                            
                                userType !== '0' && (
                                    (userType === '1' && isMinted2023) || (userType === '2' && isMinted2022) || 
                                    ((userType === '3' && isMinted2022 && isMinted2023)) || (userType === '4' && isMintedStaff)
                                ) ? (
                                        <Alert severity="success" sx={{backgroundColor: "#04c5e7", width: "100%"}}>
                                            <AlertTitle>Success</AlertTitle>
                                            You already minted NFT. Thank you.
                                        </Alert>
                                    ): (
                                        <>
                                            {
                                                (userType !== "0" && (userType === '2' || userType === '3' ) && !isMinted2022) && 
                                                <img src={player2022Img} alt='hodler' className={styles.hodler} />
                                            }

                                            {
                                                (userType !== '0' && (userType === '1' || userType === '3') && !isMinted2023) && 
                                                <img 
                                                    src={player2023Img} 
                                                    alt='hodler' 
                                                    className={styles.hodler} 
                                                />
                                            }

                                            {
                                                (userType !== '0' && userType === '4' && !isMintedStaff) && 
                                                <img src={staffImg} alt='hodler' className={styles.hodler} />
                                            }
                                        </>
                                )
                            )
                        }

                        {
                            wallet !== "" && userType === '0' && (
                                <Alert severity="warning" sx={{backgroundColor: "#04c5e7", width: "100%"}}>
                                    <AlertTitle> You are not in the whitelist!</AlertTitle>
                                </Alert>
                            )
                        }
                    </Stack> 
                </Grid>
            </Grid>
            <Box className={styles.messageBox}>
                <Box className={styles.marquee}>
                    <Box className={styles.marqueeContent}>
                        <Box className={styles.marqueeItem}>
                            <span>THE WEB3 TEAM OF MIAMI</span>
                            <img src={Star} alt='star' />
                        </Box>

                        <Box className={styles.marqueeItem}>
                            <span>THE WEB3 TEAM OF MIAMI</span>
                            <img src={Star} alt='star' />
                        </Box>

                        <Box className={styles.marqueeItem}>
                            <span>THE WEB3 TEAM OF MIAMI</span>
                            <img src={Star} alt='star' />
                        </Box>
                    </Box>

                    <Box className={styles.marqueeContent}>
                        <Box className={styles.marqueeItem}>
                            <span>THE WEB3 TEAM OF MIAMI</span>
                            <img src={Star} alt='star' />
                        </Box>

                        <Box className={styles.marqueeItem}>
                            <span>THE WEB3 TEAM OF MIAMI</span>
                            <img src={Star} alt='star' />
                        </Box>

                        <Box className={styles.marqueeItem}>
                            <span>THE WEB3 TEAM OF MIAMI</span>
                            <img src={Star} alt='star' />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <UserForm 
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                user={user}
                userType={userType}
                isMinted2022={isMinted2022}
                isMinted2023={isMinted2023}
                isMintedStaff={isMintedStaff}
                connectedWallet={wallet}
                nftContract={nftContract}
                totalPrice={totalPrice}
                totalETHPrice={totalETHPrice}
                isChecked2023NFT={checkboxes.year2023}
                mintProcess={mintProcess}
            />
            
        </Box>
    );
};

export default Mint;
